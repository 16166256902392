import * as React from "react";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { TextInput } from "ui-components";
import { useAppDispatch } from "../../redux/hooks";
import { handleToast } from "../../utils/helpers";
import { SUCCESS_PUT } from "../../constants/messageConstants";
import { setPassword } from "../../redux/actions/authActions";

type Inputs = {
  password: string;
  confirmedPassword: string;
};

export const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.password !== data.confirmedPassword) {
      handleToast("Passwords are not equals", "error");
      return;
    }
    dispatch(
      setPassword({
        token: token!,
        password: data.password,
      })
    )
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_PUT);
        navigate("/login");
      });
  };

  return (
    <div className="login">
      <div className="login__box">
        <div className="login__header">
          <div className="login__header-inner">
            <img
              src="/images/logo-simple-orange.svg"
              alt=""
              className="login__logo"
            />
            <h1 className="login__heading">Vega IT Workspace</h1>
          </div>
          <h2 className="login__subheading">Set Password</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="form login__form">
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <div className="form__row form__row--fullwidth">
                <TextInput
                  error={
                    errors.password?.type === "required"
                      ? "Password is required"
                      : null
                  }
                  label="Password"
                  placeholder="Enter password..."
                  type="password"
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="confirmedPassword"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <div className="form__row form__row--fullwidth">
                <TextInput
                  error={
                    errors.confirmedPassword?.type === "required"
                      ? "Confirm password"
                      : null
                  }
                  label="Confirm password"
                  placeholder="Confirm password..."
                  type="password"
                  {...field}
                />
              </div>
            )}
          />

          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--orange">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
