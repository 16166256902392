import { createSlice } from "@reduxjs/toolkit";

import { EmployeeState } from "../states/EmployeeState";
import {
  getEmployees,
  getEmployee,
  getAssignedEmployees,
} from "../actions/employeeActions";

const initialState: EmployeeState = {
  employees: [],
  employeesLoadingState: 'NotLoaded',
  assignedEmployees: [],
  assignedEmployeesLoadingState: 'NotLoaded',
  employeeLoadingState: 'NotLoaded',
  success: false,
};

const EmployeeSlice = createSlice({
  name: "Employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.employeesLoadingState = 'Loading';
        state.success = false;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.employeesLoadingState = 'Loaded';
        state.success = true;
        state.employees = action.payload;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.employeesLoadingState = 'Failed';
        state.success = false;
      })
      .addCase(getEmployee.pending, (state) => {
        state.employeeLoadingState = 'Loading';
        state.success = false;
      })
      .addCase(getEmployee.fulfilled, (state) => {
        state.employeeLoadingState = 'Loaded';
        state.success = true;
      })
      .addCase(getEmployee.rejected, (state) => {
        state.employeeLoadingState = 'Failed';
        state.success = false;
      })
      .addCase(getAssignedEmployees.pending, (state) => {
        state.assignedEmployeesLoadingState = 'Loading';
        state.success = false;
      })
      .addCase(getAssignedEmployees.fulfilled, (state, {payload}) => {
        state.assignedEmployeesLoadingState = 'Loaded';
        state.assignedEmployees = payload;
        state.success = true;
      })
      .addCase(getAssignedEmployees.rejected, (state) => {
        state.assignedEmployeesLoadingState = 'Failed';
        state.success = false;
      });
  },
});

export default EmployeeSlice.reducer;
