import * as React from "react";

import { useParams, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getDailyAccessControl, getCurrentUser } from "../../redux/actions/accessControlActions";
import { AppRoutes } from "../../config";
import { TimeConverter } from "../../utils/timeConverter";

export const DailyAccessControl: React.FC = () => {
  const { from, to, employeeId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, dailyReports } = useAppSelector((state) => state.accessControl);

  const handleNavigate = (date: string) => {
    navigate(AppRoutes.createRouteForWeekAccessControl(Number(employeeId), date));
  };

  React.useEffect(() => {
    dispatch(getCurrentUser(Number(employeeId)));
    dispatch(getDailyAccessControl({ employeeId: Number(employeeId), from: from!, to: to! }));
  }, [dispatch, employeeId, from, to]);

  return (
    <div className="gate">
      <div className="gate__box gate__box--split-view">
        <div className="gate__user-info">
          <h3 className="gate__box-name">{user?.employeeFullName}</h3>
          <span className="gate__box-number">
            <strong>Token number:</strong> {user?.employeeCardNumber}
          </span>

          <span className="gate__box-total-time">
            <strong>Total time in space:</strong> {user?.timeInSpace}
          </span>
        </div>
        <table className="gate__table">
          <thead>
            <tr>
              <th className="gate__table-head">Day of week</th>
              <th className="gate__table-head">Date</th>
              <th className="gate__table-head">Hours</th>
            </tr>
          </thead>
          <tbody>
            {dailyReports.map((day) => {
              return (
                <tr key={day.date}>
                  <td className="gate__table-item">
                    {TimeConverter.getFormatedTime(day.date!, "dddd")}
                  </td>
                  <td className="gate__table-item">
                    <button
                      type="button"
                      onClick={() =>
                        handleNavigate(TimeConverter.getFormatedTime(day.date!, "YYYY-MM-DD"))
                      }
                      className="gate__table-button"
                    >
                      {TimeConverter.getFormatedTime(day.date!, "DD.MM.YYYY")}
                    </button>
                  </td>
                  <td className="gate__table-item">{day.timeInSpace}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
