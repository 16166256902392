import React, { useState } from 'react';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string;
  error: string | null;
  onChangeSelection: (e: string) => void;
  setValue: (value: string) => void;
  statuses: string[];
  valueForEdit?: string;
}

export const RadioButtons: React.FC<Props> = React.forwardRef(
  (
    { label, statuses, error, onChangeSelection, setValue, valueForEdit },
    ref
  ) => {
    const [gender, setGender] = useState('');

    const handleChange = (item: string) => {
      setGender(item);
      onChangeSelection(item);
      setValue(item);
    };

    return (
      <div className='form__item'>
        <span className='form__label'>{label}</span>
        {statuses.map((item, index) => (
          <div
            className='form__radio'
            onClick={() => handleChange(item)}
            key={index}
          >
            <input
              ref={ref}
              type='radio'
              value={item}
              id={`radio${index}`}
              name='radioGroup'
              checked={gender === item || valueForEdit === item}
              className='form__radio-input'
              onChange={() => {}}
            />
            <label className='form__radio-label'>{item}</label>
          </div>
        ))}
        <span className='form__item-error'>{error}</span>
      </div>
    );
  }
);
