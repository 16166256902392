import { TokenInfoResponse } from "api/generated";
import { AppRoutes } from "config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTokenInfo } from "redux/actions/accessControlActions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { handleErrors } from "utils/helpers";

const TokenInfo = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.accessControl.isLoading);
  const [tokenInfo, setTokenInfo] = useState<TokenInfoResponse | undefined>();

  useEffect(() => {
    dispatch(getTokenInfo()).unwrap().then(setTokenInfo).catch(handleErrors);
  }, [dispatch]);

  return (
    <>
      <div className="token">
        <div className="token__col">
          <h3 className="token__col-title token__col-title--info">My token</h3>
          {tokenInfo ? (
            <div className="token__info">
              <p className="token__info-text">
                <span>Your name:</span>
                <span>{tokenInfo.name}</span>
              </p>
              <p className="token__info-text">
                <span>Your organization:</span>
                <span>{tokenInfo.organization}</span>
              </p>
              <p className="token__info-text">
                <span>Your token number:</span>
                <span>{tokenInfo.token}</span>
              </p>
              <p className="token__info-text">
                <span>Today's hours:</span>
                <span>{tokenInfo.timeInSpace}</span>
              </p>
            </div>
          ) : isLoading ? null : (
            <div className="token__info">
              <p className="token__info-text">
                We couldn't find your token.
                <br />
                If you think this is a mistake, please contact PES team.
              </p>
            </div>
          )}
        </div>
        <div className="token__col">
          <h3 className="token__col-title">Looking for someone else?</h3>
          <div className="token__info">
            <p className="token__info-text token__info-text--small">
              If you want to check your hours from different date range or you
              are looking for someone else, click the button below to customize
              your search.
            </p>
            <Link to={AppRoutes.createRouteForAccessControl()} className="btn btn--black">
              see more
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenInfo;
