import { createSlice } from "@reduxjs/toolkit";

import { BuildingState } from "../states/BuildingState";
import { getBuildings, getBuilding } from "../actions/buildingActions";

const initialState: BuildingState = {
  success: false,
  buildingsLoadingState: "NotLoaded",
  buildingLoadingState:  "NotLoaded",
  buildings: [],
  isEditing: false,
};

const BuildingSlice = createSlice({
  name: "Building",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuildings.pending, (state) => {
        state.success = true;
        state.buildingsLoadingState = 'Loading';
      })
      .addCase(getBuildings.fulfilled, (state, action) => {
        state.success = true;
        state.buildings = action.payload;
        state.buildingsLoadingState = 'Loaded';
      })
      .addCase(getBuildings.rejected, (state) => {
        state.success = false;
        state.buildingsLoadingState = 'Failed';
      })
      .addCase(getBuilding.pending, (state) => {
        state.buildingLoadingState = 'Loading';
        state.success = true;
      })
      .addCase(getBuilding.fulfilled, (state, action) => {
        state.buildingLoadingState = 'Loaded';
        state.success = true;
        state.building = action.payload;
      })
      .addCase(getBuilding.rejected, (state) => {
        state.buildingLoadingState = 'Failed';
        state.success = false;
      });
  },
});

export default BuildingSlice.reducer;
