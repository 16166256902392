import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import AuthSlice from "./slices/authSlice";
import BuildingSlice from "./slices/buildingSlice";
import EmployeeSlice from "./slices/employeeSlice";
import FloorSlice from "./slices/floorSlice";
import AccessControlSlice from "./slices/accessControlSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: AuthSlice,
  building: BuildingSlice,
  employee: EmployeeSlice,
  floor: FloorSlice,
  accessControl: AccessControlSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk as ThunkMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
