import * as React from "react";

import { useParams, useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getBasicAccessControl } from "../../redux/actions/accessControlActions";
import { AppRoutes } from "../../config/routes";

export const BasicAccessControl: React.FC = () => {
  const { name, from, to } = useParams();
  const dispatch = useAppDispatch();
  const { basicReports } = useAppSelector((state) => state.accessControl);
  const navigate = useNavigate();

  const handleNavigate = (employeeId: number) => {
    navigate(AppRoutes.createRouteForDailyAccessControl(employeeId, from!, to!));
  };

  React.useEffect(() => {
    dispatch(getBasicAccessControl({ name: name!, from: from!, to: to! }));
  }, [dispatch, name, from, to]);

  return (
    <div className="gate">
      <div className="gate__box">
        <table className="gate__table">
          <thead>
            <tr>
              <th className="gate__table-head">Employee</th>
              <th className="gate__table-head">Token number</th>
              <th className="gate__table-head">Hours</th>
            </tr>
          </thead>
          <tbody>
            {basicReports.map((item) => {
              return (
                <tr key={item.employeeId}>
                  <td className="gate__table-item">
                    <button
                      onClick={() => handleNavigate(item.employeeId!)}
                      type="button"
                      className="gate__table-button"
                    >
                      {item.employeeFullName}
                    </button>
                  </td>
                  <td className="gate__table-item">{item.employeeCardNumber}</td>
                  <td className="gate__table-item">
                    <b>Today:</b> {item.timeInSpaceToday}
                    &nbsp;
                    <b>Total time for selected date range: </b>
                    {item.timeInSpace}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
