import { createAsyncThunk } from "@reduxjs/toolkit";

import { EmployeeResponse, EmployeeResponseWithReservations } from "../../api/generated/index";
import { Api } from "../../api/index";

export const getEmployees = createAsyncThunk("getEmployees", async (): Promise<EmployeeResponse[]> => {
  return Api.Employee.getAllEmployees().then(({ data }) => {
    return data;
  });
});

export const getEmployee = createAsyncThunk("getEmployee", async (id: number): Promise<EmployeeResponse> => {
  return Api.Employee.getEmployeeById(id).then(({ data }) => {
    return data;
  });
});
export const getAssignedEmployees = createAsyncThunk(
  "getAssignedEmployees",
  async (): Promise<EmployeeResponseWithReservations[]> => {
    return Api.Employee.getAllAssignedEmployees().then(({ data }) => {
      return data;
    });
  },
);
