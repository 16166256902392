import {
  AuthenticationControllerApi,
  BuildingControllerApi,
  EmployeeControllerApi,
  FloorControllerApi,
  ReportsControllerApi,
} from "./generated";
import { backendAxiosInstance } from "../services/services";
import { API_URL_WORKSPACE } from "../constants/appConstants";

export const Api = {
  Auth: new AuthenticationControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Building: new BuildingControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Employee: new EmployeeControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Floor: new FloorControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Reports: new ReportsControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
};
