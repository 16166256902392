import * as React from "react";

interface ModalProps {
  show: boolean;
  size?: "small" | "medium" | "large";
  handleClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  show,
  handleClose,
  children,
  size
}) => {
  return (
    <div className="popup" style={{ display: show ? "block" : "none" }}>
      <span className="popup__background"></span>
      <div className={`popup__content popup__content--${size}`}>
        <button type="button" className="popup__close" onClick={handleClose}>
          <span className="sr-only">Close popup</span>
        </button>
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  size: "medium"
};