import * as React from "react";

export const Loader: React.FC = () => {
  return (
    <div className="loader">
      <div className="loader__dot"></div>
      <div className="loader__dot"></div>
      <div className="loader__dot"></div>
    </div>
  );
};
