import classNames from 'classnames';
import React, { useState } from 'react';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string;
  error: string | null;
  placeholder: string;
  onChangeSelection: (e: string) => void;
  options: any[];
}

export const Dropdown: React.FC<Props> = React.forwardRef(
  (
    { label, error, placeholder, onChangeSelection, options, ...props }: Props,
    ref
  ) => {
    const divClass = classNames('form__item', {
      'form__item--required': error
    });

    const buttonClass = classNames('form__dropdown js-dropdown', {
      'form-error': error
    });

    const divRef = React.useRef<HTMLDivElement>(null);

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const handleButtonClick = () => {
      const classDropdownOpen = 'form__dropdown--active';
      const dropdown = divRef.current;

      if (dropdown?.classList.contains(classDropdownOpen)) {
        dropdown.classList.remove(classDropdownOpen);
      } else {
        dropdown?.classList.add(classDropdownOpen);
      }
    };

    const handleItemClick = (itemIndex: number) => {
      const classDropdownOpen = 'form__dropdown--active';
      setSelectedIndex(itemIndex);
      divRef.current!.classList.remove(classDropdownOpen);

      onChangeSelection(options[itemIndex]);
    };

    const placeholderText =
      selectedIndex !== null ? options[selectedIndex!] : placeholder;

    const optionsList = options.map((item, i) => {
      const inputClass = classNames('form__dropdown-item js-dropdown-item', {
        'form-error': selectedIndex === i
      });
      return (
        <li onClick={() => handleItemClick(i)} className={inputClass} key={i}>
          {item}
        </li>
      );
    });

    return (
      <div className={divClass} {...props}>
        <span className='form__label'>{label}</span>
        <div className={buttonClass} ref={divRef}>
          <button
            onClick={() => handleButtonClick()}
            type='button'
            className='form__dropdown-btn form__dropdown-btn--placeholder js-dropdown-btn'
          >
            {placeholderText}
          </button>
          <ul className='form__dropdown-list'>{optionsList}</ul>
          <span className='form__item-error'>{error}</span>
          <input type='hidden' ref={ref} />
        </div>
      </div>
    );
  }
);
