const BUILDING_PREFIX = "building";
const FLOOR_PREFIX = "floor";
const EMPLOYEES_PREFIX = "employees";
const ACCESS_CONTROL_PREFIX = "token";
const LOGIN_PREFIX = "login";

export const AppRoutes = {
  error: "*",
  login: `/${LOGIN_PREFIX}`,
  workspace: ``,
  accessControl: `/${ACCESS_CONTROL_PREFIX}`,
  basicAccessControl: "employees/:name/:from/:to",
  dailyAccessControl: "employee/:employeeId/:from/:to",
  weekAccessControl: "employee/:employeeId/:date",
  employees: `/${EMPLOYEES_PREFIX}/:type`,
  employeesFind: `/${EMPLOYEES_PREFIX}/:type/:id`,
  building: `${BUILDING_PREFIX}/:buildingId`,
  floor: `${FLOOR_PREFIX}/:floorId`,
  workstation: `${FLOOR_PREFIX}/:floorId/:officeId/:workstationId`,

  createRouteForWorkspace: () => {
    return `/`;
  },

  createRouteForAccessControl: () => {
    return `/${ACCESS_CONTROL_PREFIX}`;
  },

  createRouteForLogin: () => {
    return `/${LOGIN_PREFIX}`;
  },

  createRouteForBuilding: (buildingId: number) => {
    return `/${BUILDING_PREFIX}/${buildingId}`;
  },

  createRouteForFloor: (floorId: number) => {
    return `/${FLOOR_PREFIX}/${floorId}`;
  },
  createRouteForWorkstation: (floorId: number, officeId: number, workstationId: string) => {
    return `/${FLOOR_PREFIX}/${floorId}/${officeId}/${workstationId}`;
  },
  createRouteForEmployees: (type: string) => {
    return `/${EMPLOYEES_PREFIX}/${type}`;
  },
  createRouteForEmployeeFind: (type: string, id: number) => {
    return `/${EMPLOYEES_PREFIX}/${type}/${id}`;
  },
  createRouteForBasicAccessControl: (name: string, from: string, to: string) => {
    return `/${ACCESS_CONTROL_PREFIX}/employees/${name}/${from}/${to}`;
  },
  createRouteForDailyAccessControl: (employeeId: number, from: string, to: string) => {
    return `/${ACCESS_CONTROL_PREFIX}/employee/${employeeId}/${from}/${to}`;
  },
  createRouteForWeekAccessControl: (employeeId: number, date: string) => {
    return `/${ACCESS_CONTROL_PREFIX}/employee/${employeeId}/${date}`;
  },
};
