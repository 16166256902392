import * as React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Login } from "./pages/login";
import { NotFound } from "./pages/notFound";
import { WorkspaceLayout, AccessControlLayout } from "./layouts/subLayout";
import { AuthenticatedRoute } from "./pages/authenticatedRoute";
import { Employee } from "./pages/employee";
import { Building } from "./pages/building";
import { Floor } from "./pages/floor";
import { Home } from "./pages/home";
import { SetPassword } from "./pages/setPassword";
import { BasicAccessControl, DailyAccessControl, WeekAccessControl } from "./pages/accessControl";
import { MainLayout } from "./layouts/mainLayout";
import { AppRoutes } from "./config";

import "./App.css";
import "./config/interceptors";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            path=""
            element={
              <AuthenticatedRoute>
                <Home />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={AppRoutes.employees}
            element={
              <AuthenticatedRoute>
                <Employee />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={AppRoutes.employeesFind}
            element={
              <AuthenticatedRoute>
                <Employee />
              </AuthenticatedRoute>
            }
          />

          <Route path={AppRoutes.accessControl} element={<AccessControlLayout />}>
            <Route
              path={AppRoutes.basicAccessControl}
              element={
                <AuthenticatedRoute>
                  <BasicAccessControl />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={AppRoutes.dailyAccessControl}
              element={
                <AuthenticatedRoute>
                  <DailyAccessControl />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={AppRoutes.weekAccessControl}
              element={
                <AuthenticatedRoute>
                  <WeekAccessControl />
                </AuthenticatedRoute>
              }
            />
          </Route>

          <Route path={AppRoutes.workspace} element={<WorkspaceLayout />}>
            <Route
              path={AppRoutes.building}
              element={
                <AuthenticatedRoute>
                  <Building />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={AppRoutes.floor}
              element={
                <AuthenticatedRoute>
                  <Floor />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={AppRoutes.workstation}
              element={
                <AuthenticatedRoute>
                  <Floor />
                </AuthenticatedRoute>
              }
            />
          </Route>
        </Route>
        <Route path={AppRoutes.login} element={<Login />} />
        <Route
          path="auth/setPassword/:username/:token"
          element={<SetPassword />}
        />
        <Route path={AppRoutes.error} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
