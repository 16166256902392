import moment from "moment";

export const TimeConverter = {
  getTimeFromUnix: (time: any) => {
    return moment.unix(time);
  },
  getFormatedTime: (time: any, format: string) => {
    return moment(time).format(format);
  },
  getMomentTimeFormated: (time: any, format: string) => {
    return moment(time, format);
  },
  getMomentTime: (time: any) => {
    return moment(time);
  },
};
