import { createAsyncThunk } from "@reduxjs/toolkit";

import { Api } from "../../api";
import {
  TimeInSpaceBasicReportResponse,
  TimeInSpaceWeekDayReportResponse,
  EmployeeStillInsideResponse,
  TimeInSpaceWeekReportResponse,
  TokenInfoResponse,
} from "../../api/generated";
import {
  BasicAccessControlType,
  DailyAccessControlType,
  WeekAccessControlType,
} from "../../types/AccessControlType";

export const getBasicAccessControl = createAsyncThunk(
  "getBasicAccessControl",
  async (
    basicAccessControlType: BasicAccessControlType
  ): Promise<TimeInSpaceBasicReportResponse[]> => {
    const employeeName = basicAccessControlType.name !== "all" ? basicAccessControlType.name : "";
    return Api.Reports.getBasicReports(
      basicAccessControlType.from,
      basicAccessControlType.to,
      employeeName
    ).then(({ data }) => {
      return data;
    });
  }
);

export const getDailyAccessControl = createAsyncThunk(
  "getDailyAccessControl",
  async (
    dailyAccessControlType: DailyAccessControlType
  ): Promise<TimeInSpaceWeekDayReportResponse[]> => {
    return Api.Reports.getDailyReports(
      dailyAccessControlType.from,
      dailyAccessControlType.to,
      dailyAccessControlType.employeeId
    ).then(({ data }) => {
      return data;
    });
  }
);

export const getWeekAccessControl = createAsyncThunk(
  "getWeekAccessControl",
  async (weekAccessControlType: WeekAccessControlType): Promise<TimeInSpaceWeekReportResponse> => {
    return Api.Reports.getWeekReports(
      weekAccessControlType.date,
      weekAccessControlType.employeeId
    ).then(({ data }) => {
      return data;
    });
  }
);

export const getCurrentUser = createAsyncThunk(
  "getCurrentUser",
  async (employeeId: number): Promise<number> => {
    return employeeId;
  }
);

export const getStillInsideEmployees = createAsyncThunk(
  "getStillInsideEmployees",
  async (): Promise<EmployeeStillInsideResponse[]> => {
    return Api.Reports.getStillInsideReports().then(({ data }) => {
      return data;
    });
  }
);

export const getTokenInfo = createAsyncThunk(
  "getTokenInfo",
  async (): Promise<TokenInfoResponse> => {
    return Api.Reports.getTokenInfo().then(({ data }) => {
      return data;
    });
  }
);
