import React from 'react';

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  label: string;
  placeholder: string;
}

export const TextArea: React.FC<Props> = React.forwardRef(
  ({ label, placeholder, ...props }, ref) => {
    return (
      <div className='form__item'>
        <label className='form__label'>{label}</label>
        <textarea
          {...props}
          id='textarea'
          className='form__textarea'
          placeholder={placeholder}
          ref={ref}
        ></textarea>
      </div>
    );
  }
);
