import React from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string;
  error: string | null;
  isDisabled?: boolean;
  type?: string;
}

export const TextInput: React.FC<Props> = React.forwardRef(
  ({ label, error, isDisabled, type = 'text', ...props }, ref) => {
    const divClass = classNames('form__item', {
      'form__item--required': error
    });

    const inputClass = classNames('form__input', {
      'form-error': error
    });

    return (
      <div className={divClass}>
        <label className='form__label'>{label}</label>
        <input {...props} type={type} className={inputClass} ref={ref} />
        <span className='form__item-error'>{error}</span>
      </div>
    );
  }
);
