import * as React from "react";

import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TextInput } from "ui-components";
import { useAppSelector } from "../../redux/hooks";
import { AppRoutes } from "../../config/routes";
import { handleToast } from "../../utils/helpers";
import { TimeConverter } from "../../utils/timeConverter";

export const AccessControlNav = () => {
  const navigate = useNavigate();
  const { name } = useAppSelector((state) => state.auth);
  const [searchName, setSearchName] = React.useState<string>(name);
  const [from, setFrom] = React.useState<string>(
    TimeConverter.getFormatedTime(new Date(), "YYYY-MM-DD")
  );
  const [to, setTo] = React.useState<string>(
    TimeConverter.getFormatedTime(new Date(), "YYYY-MM-DD")
  );

  const onSearchChange = (e: any) => {
    setSearchName(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const onFromDateChange = (e: any) => {
    const date: string = e.target.value;

    if (
      TimeConverter.getMomentTimeFormated(date, "YYYY-MM-DD") >
      TimeConverter.getMomentTimeFormated(to, "YYYY-MM-DD")
    ) {
      handleToast("Start date can't be bigger than end date", "error");
      return;
    }
    setFrom(date);
  };

  const onToDateChange = (e: any) => {
    const date: string = e.target.value;

    if (
      TimeConverter.getMomentTimeFormated(date, "YYYY-MM-DD") <
      TimeConverter.getMomentTimeFormated(from, "YYYY-MM-DD")
    ) {
      handleToast("End date can't be smaller than start date", "error");
      return;
    }
    setTo(date);
  };

  const handleNavigate = (name: string, from: string, to: string) => {
    navigate(AppRoutes.createRouteForBasicAccessControl(name, from, to));
  };

  const handleSearch = () => {
    const value: string = searchName === "" ? "all" : searchName;
    handleNavigate(value, from, to);
  };

  React.useEffect(() => {
    const today: string = TimeConverter.getFormatedTime(new Date(), "YYYY-MM-DD");
    handleNavigate(name, today, today);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleBackButtonView = () => {
    return (
      <li className="panel-head__item panel-head__item--back">
        <button type="button" className="btn-back" onClick={handleBack}>
          Back
        </button>
      </li>
    );
  };

  return (
    <div className="panel-head panel-head--page">
      <ul className="panel-head__list panel-head__list--entrence">
        {handleBackButtonView()}
        <TextInput
          label="Name"
          value={searchName}
          onChange={onSearchChange}
          onKeyDown={onKeyDown}
          placeholder="Name..."
          error={null}
        />
        <TextField label="From" type="date" value={from} onChange={onFromDateChange} />
        <TextField label="To" type="date" value={to} onChange={onToDateChange} />
        <button type="button" className="btn btn--green" onClick={handleSearch}>
          Find
        </button>
      </ul>
    </div>
  );
};
