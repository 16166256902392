import { Link } from "react-router-dom";
import locationIcon from "../../images/ico-location-dot.svg";

export interface CardProps {
  buildingName?: string;
  buidlingAddress?: string;
  buildingAddressName?: string;
  exploreUrl: string;
}

export const Card = ({
  buildingName,
  buildingAddressName,
  buidlingAddress,
  exploreUrl,
}: CardProps) => {
  return (
    <div className="card">
      <div className="card__content">
        <h3 className="card__content-name">{buildingName}</h3>
        <div className="card__location">
          <img
            src={locationIcon}
            alt="Location Icon"
            className="card__location-icon"
          />
          <a
            href={buidlingAddress}
            className="card__location-link"
            target="_blank"
            rel="noreferrer"
          >
            {buildingAddressName}
          </a>
        </div>
        <Link
          to={exploreUrl}
          className="btn btn--white"
          rel="noreferrer"
        >
          Explore
        </Link>
      </div>
    </div>
  );
};
