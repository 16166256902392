import { createSlice } from "@reduxjs/toolkit";

import { FloorState } from "../states/FloorState";
import { getFloors, getFloor, getSvg } from "../actions/floorActions";

const initialState: FloorState = {
  floors: [],
  floorsLoadingState: 'NotLoaded',
  floorLoadingState: 'NotLoaded',
  success: false,
  svgLoadingState: 'NotLoaded',
};

const FloorSlice = createSlice({
  name: "Floor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFloors.pending, (state) => {
        state.floorsLoadingState = 'Loading';
        state.success = false;
      })
      .addCase(getFloors.fulfilled, (state, action) => {
        state.floorsLoadingState = 'Loaded';
        state.success = true;
        state.floors = action.payload;
      })
      .addCase(getFloors.rejected, (state) => {
        state.floorsLoadingState = 'Failed';
        state.success = false;
      })
      .addCase(getFloor.pending, (state) => {
        state.floorLoadingState = 'Loading';
        state.success = false;
      })
      .addCase(getFloor.fulfilled, (state, action) => {
        state.floorLoadingState = 'Loaded';
        state.success = true;
        state.floor = action.payload;
      })
      .addCase(getFloor.rejected, (state) => {
        state.floorLoadingState = 'Failed';
        state.success = false;
      })
      .addCase(getSvg.pending, (state) => {
        state.svgLoadingState = 'Loading';
        state.success = false;
      })
      .addCase(getSvg.fulfilled, (state) => {
        state.svgLoadingState = 'Loaded';
        state.success = true;
      })
      .addCase(getSvg.rejected, (state) => {
        state.svgLoadingState = 'Failed';
        state.success = false;
      });
  },
});

export default FloorSlice.reducer;
