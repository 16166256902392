import * as React from "react";

import { Outlet } from "react-router-dom";

import { AccessControlNav } from "../../components/nav";

export const AccessControlLayout: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <AccessControlNav />
        <Outlet />
      </div>
    </>
  );
};
