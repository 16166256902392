import toast from "react-hot-toast";

import { EXPIRED_TOKEN } from "../constants/messageConstants";

export const handleToast = (
  message: string,
  type?: "success" | "warning" | "error"
) => {
  if (type === "warning") {
    toast(message, {
      duration: 1000,
      icon: "⚠",
      style: {
        fontWeight: "bold",
        background: "#ffeb3b",
      },
    });
    return;
  }

  if (type === "error") {
    toast.error(message, {
      duration: 1000,
      style: {
        color: "red",
        background: "white",
        fontWeight: "bold",
      },
    });
    return;
  }

  toast.success(message, {
    duration: 1000,
    style: {
      color: "green",
      background: "white",
      fontWeight: "bold",
    },
  });
};
export const handleErrors = (response: any) => {
  if (!response) return;

  if (response.message.includes("status code 401")) {
    localStorage.removeItem("persist:root");
    handleToast(EXPIRED_TOKEN, "error");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    return;
  }
  handleToast(response.message, "error");
};
