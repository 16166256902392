import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TextInput } from "ui-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login } from "../../redux/actions/authActions";
import { LoginType } from "../../types/LoginType";
import { Loader } from "../../components/loader/Loader";
import { handleToast } from "../../utils/helpers";
import logo from "../../images/vegai-logo-white.svg";

type Inputs = {
  username: string;
  password: string;
};

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const loginType: LoginType = {
      emailAddress: data.username,
      password: data.password,
    };
    dispatch(login(loginType))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        handleToast(error.message, "error");
      });
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  return (
    <div className="login">
      {handleLoader()}
      <div className="login__header">
        <div className="login__header-inner">
          <img src={logo} alt="" className="login__logo" />
        </div>
      </div>
      <div className="login__box">
        <h2 className="login__subheading">Login</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="form login__form">
          <Controller
            name="username"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <div className="form__row form__row--fullwidth">
                <TextInput
                  error={
                    errors.username?.type === "required"
                      ? "Username is required"
                      : null
                  }
                  label="Username"
                  placeholder="Your username..."
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <div className="form__row form__row--fullwidth">
                <TextInput
                  error={
                    errors.password?.type === "required"
                      ? "Password is required"
                      : null
                  }
                  label="Password"
                  placeholder="Your password..."
                  type="password"
                  {...field}
                />
              </div>
            )}
          />

          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--black">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
