import { createAsyncThunk } from "@reduxjs/toolkit";

import { BuildingWithFloorInfoResponse } from "../../api/generated/index";
import { Api } from "../../api/index";

export const getBuildings = createAsyncThunk(
  "getBuildings",
  async (): Promise<BuildingWithFloorInfoResponse[]> => {
    return Api.Building.getAllBuildings().then(({ data }) => {
      return data;
    });
  }
);

export const getBuilding = createAsyncThunk(
  "getBuilding",
  async (id: number): Promise<BuildingWithFloorInfoResponse> => {
    return Api.Building.getBuildingById(id).then(({ data }) => {
      return data;
    });
  }
);
