import * as React from "react";

import { Navigate, useLocation } from "react-router-dom";

import { useAppSelector } from "../../redux/hooks";
import { AppRoutes } from "../../config";
import { TimeConverter } from "../../utils/timeConverter";

interface IAuthenticatedRouteProps {
  children: JSX.Element;
}

export const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = ({
  children,
}) => {
  const location = useLocation();
  const { isAuthenticated, exp } = useAppSelector((state) => state.auth);

  if (
    !isAuthenticated ||
    TimeConverter.getTimeFromUnix(exp) < TimeConverter.getMomentTime(new Date())
  ) {
    localStorage.clear();
    return (
      <Navigate
        to={AppRoutes.createRouteForLogin()}
        state={{ from: location }}
      />
    );
  }

  return children;
};
