import { Outlet } from "react-router-dom";
import { WorkspaceNav } from "../../components/nav/WorkspaceNav";

export const WorkspaceLayout: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <WorkspaceNav />
        <Outlet />
      </div>
    </>
  );
};
