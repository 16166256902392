import classNames from 'classnames';
import React, { useState } from 'react';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string;
  error: string | undefined;
  placeholder: string;
  onChangeSelection: (e: string[]) => void;
}

export const DropdownMultiselect: React.FC<Props> = React.forwardRef(
  ({ label, error, placeholder, onChangeSelection }: Props, ref) => {
    const divClass = classNames('form__item', {
      'form__item--required': error
    });

    const buttonClass = classNames('form__dropdown js-dropdown', {
      'form-error': error
    });

    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const divRef = React.useRef<HTMLDivElement>(null);
    const ulRef = React.useRef<HTMLUListElement>(null);

    const [selectedIndex, setSelectedIndex] = useState<string[]>([]);

    const handleButtonClick = () => {
      const classDropdownOpen = 'form__dropdown--active';
      const dropdown = divRef.current;

      if (dropdown?.classList.contains(classDropdownOpen)) {
        dropdown.classList.remove(classDropdownOpen);
      } else {
        dropdown?.classList.add(classDropdownOpen);
      }
    };

    const handleItemClick = (item: string) => {
      const foundIndex = selectedIndex.findIndex((element) => element === item);
      if (foundIndex === -1) {
        setSelectedIndex([...selectedIndex, item]);
        onChangeSelection([...selectedIndex, item]);

        return;
      }
      const newItems = selectedIndex.filter((element) => element !== item);
      setSelectedIndex(newItems);
      onChangeSelection(newItems);
    };

    const options = [
      'element1',
      'element2',
      'element3',
      'element4',
      'element5',
      'element6'
    ];

    const placeholderText =
      selectedIndex.length !== 0 ? placeholder : placeholder;

    return (
      <div className={divClass}>
        <span className='form__label'>{label}</span>
        <div className={buttonClass} ref={divRef}>
          <button
            ref={buttonRef}
            onClick={() => handleButtonClick()}
            type='button'
            className='form__dropdown-btn form__dropdown-btn--placeholder js-dropdown-btn'
          >
            {placeholderText}
          </button>
          <ul className='form__dropdown-list' ref={ulRef}>
            {options.map((item, i) => {
              const inputClass = classNames(
                'form__dropdown-item form__dropdown-item-multi js-dropdown-item',
                {
                  'form__dropdown-item--selected': selectedIndex.find(
                    (element) => item === element
                  )
                }
              );
              return (
                <li
                  onClick={() => handleItemClick(item)}
                  className={inputClass}
                  key={i}
                >
                  {item}
                </li>
              );
            })}
          </ul>
          <input type='hidden' ref={ref} />
        </div>
      </div>
    );
  }
);
