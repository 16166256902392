import { AxiosRequestConfig } from "axios";

import { backendAxiosInstance } from "../services/services";
import { store } from "../redux/store";

export const requestInterceptor = (
  request: AxiosRequestConfig | any
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const token = store.getState().auth.token;
  if (token && typeof token === "string") {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

backendAxiosInstance.interceptors.request.use(requestInterceptor);
