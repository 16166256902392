import { useEffect, useRef, useState } from "react";
import icon from "../../images/ico-chevron-down.svg";

export interface AccordionProps {
  name: string;
  children: React.ReactNode;
  isAccOpen: boolean;
}

export const Accordion = ({ name, children, isAccOpen }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isAccOpen);
  const accordionBody = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
    handleAccordionHeight();
  };

  const handleAccordionHeight = () => {
    if (accordionBody.current) {
      if (!isOpen) {
        const contentHeight = accordionBody.current.scrollHeight;
        accordionBody.current.style.maxHeight = `${contentHeight}px`;
      } else {
        accordionBody.current.style.maxHeight = "0";
      }
    }
  };

  useEffect(() => {
    if (accordionBody.current) {
      if (isOpen) {
        const contentHeight = accordionBody.current.scrollHeight;
        accordionBody.current.style.maxHeight = `${contentHeight}px`;
      } else {
        accordionBody.current.style.maxHeight = "0";
      }
    }
  }, []);

  return (
    <div className="accordion">
      <button
        type="button"
        className={`accordion__name${isOpen ? " accordion__name--open" : ""}`}
        onClick={() => {
          handleToggle();
        }}
      >
        {name}
        <img src={icon} alt="Accordion Icon" className="accordion__name-icon" />
      </button>
      <div
        className={`accordion__content${
          isOpen ? " accordion__content--open" : ""
        }`}
        ref={accordionBody}
      >
        <div className="accordion__item">{children}</div>
      </div>
    </div>
  );
};
