import * as React from "react";

import { useParams, useNavigate } from "react-router-dom";

import { Modal } from "../../components/Modal/Modal";
import { TextInput } from "ui-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { EmployeeResponse, EmployeeStillInsideResponse } from "../../api/generated";
import { getEmployees } from "../../redux/actions/employeeActions";
import { getStillInsideEmployees } from "../../redux/actions/accessControlActions";
import { EmployeeTypeEnum } from "../../enums/EmployeeTypeEnum";
import { handleErrors } from "utils/helpers";
import { Loader } from "../../components/loader/Loader";
import { AppRoutes } from "../../config/routes";

export const Employee: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoadingEmployees =
    useAppSelector((state) => state.employee.employeesLoadingState) === "Loading";
  const { stillInside, isLoading: isLoadingTokens } = useAppSelector(
    (state) => state.accessControl
  );
  const [employees, setEmployees] = React.useState<EmployeeResponse[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchEmployees, setSearchEmployees] = React.useState<EmployeeResponse[]>([]);

  const [searchEmployeesInside, setSearchEmployeesInside] = React.useState<
    EmployeeStillInsideResponse[]
  >([]);

  const [show, setShow] = React.useState<boolean>(true);

  const handleClose = () => {
    setShow(false);
    navigate(AppRoutes.createRouteForWorkspace());
  };
  const { type, id } = useParams();

  const handleModalTitle = () => {
    if (type === "home") {
      return EmployeeTypeEnum.Home;
    }
    if (type === "inactive") {
      return EmployeeTypeEnum.Inactive;
    }

    return null;
  };

  const handleModalSize = () => {
    if (type === "inside") {
      return "large";
    }

    return "medium";
  };

  const handleModalContent = (): React.ReactNode => {
    if (type === "home") {
      return (
        <>
          <div className="popup__item">
            <div className="popup__item-cell popup__item-cell--head">Name</div>
            <div className="popup__item-cell popup__item-cell--head">Company</div>
          </div>
          {searchEmployees.map((item) => (
            <div key={item.id} className="popup__item">
              <div className="popup__item-cell">{item.name}</div>
              <div className="popup__item-cell">{item.company?.name}</div>
            </div>
          ))}
        </>
      );
    }
    if (type === "inactive") {
      return (
        <>
          <div className="popup__item">
            <div className="popup__item-cell popup__item-cell--head">Name</div>
            <div className="popup__item-cell popup__item-cell--head">Company</div>
            <div className="popup__item-cell popup__item-cell--head">Reason for deactivation</div>
          </div>
          {searchEmployees.map((item) => (
            <div key={item.id} className="popup__item">
              <div className="popup__item-cell">{item.name}</div>
              <div className="popup__item-cell">{item.company?.name}</div>
              <div className="popup__item-cell">{item.reasonForDeactivation}</div>
            </div>
          ))}
        </>
      );
    }

    return (
      <>
        <div className="popup__item">
          <div className="popup__item-cell popup__item-cell--head">Name</div>
          <div className="popup__item-cell popup__item-cell--head">Token number</div>
          <div className="popup__item-cell popup__item-cell--head">Location</div>
        </div>
        {searchEmployeesInside.map((item) => (
          <div key={item.cardNo} className="popup__item">
            <div className="popup__item-cell">{item.name}</div>
            <div className="popup__item-cell">{item.cardNo}</div>
            <div className="popup__item-cell">{item.location}</div>
          </div>
        ))}
      </>
    );
  };

  const serachNavigate = (e: any, type: string) => {
    if (type === "inside") {
      handleSearchInside(e);
      return;
    }

    handleSearchHomeOrInactive(e);
  };

  const handleSearchHomeOrInactive = (e: any) => {
    const value: string = e.target.value;
    setSearchValue(value);

    if (value === "") {
      setSearchEmployees(employees);
      return;
    }

    setSearchEmployees(
      employees.filter((item) => item.name?.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleSearchInside = (e: any) => {
    const value: string = e.target.value;
    setSearchValue(value);

    if (value === "") {
      setSearchEmployeesInside(stillInside);
      return;
    }

    setSearchEmployeesInside(
      searchEmployeesInside.filter((item) => item.name?.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleLoader = () => {
    if (isLoadingEmployees || isLoadingTokens) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    if (type === "inside") {
      dispatch(getStillInsideEmployees())
        .unwrap()
        .then((res) => {
          setSearchEmployeesInside(res);
        });
      return;
    }

    dispatch(getEmployees())
      .unwrap()
      .then((employees) => {
        if (type === "home") {
          const homeEmployees: EmployeeResponse[] = employees.filter(
            (item) => item.status === EmployeeTypeEnum.Home
          );
          let employeesToDisplay: EmployeeResponse[] = [];
          if (id === undefined) {
            employeesToDisplay = employees.filter((item) => item.status === EmployeeTypeEnum.Home);
          } else {
            employeesToDisplay = employees.filter(
              (item) => item.status === EmployeeTypeEnum.Home && item.id === Number(id)
            );
          }
          setEmployees(homeEmployees);
          setSearchEmployees(employeesToDisplay);
        } else if (type === "inactive") {
          const inactiveEmployees: EmployeeResponse[] = employees.filter(
            (item) => item.status === EmployeeTypeEnum.Inactive
          );
          let employeesToDisplay: EmployeeResponse[] = [];
          if (id === undefined) {
            employeesToDisplay = employees.filter(
              (item) => item.status === EmployeeTypeEnum.Inactive
            );
          } else {
            employeesToDisplay = employees.filter(
              (item) => item.status === EmployeeTypeEnum.Inactive && item.id === Number(id)
            );
          }
          setEmployees(inactiveEmployees);
          setSearchEmployees(employeesToDisplay);
        }
      })
      .catch((res) => {
        handleErrors(res);
      });
  }, [dispatch, type, id]);

  return (
    <Modal show={show} handleClose={handleClose} size={handleModalSize()}>
      {handleLoader()}
      <div className="popup__wrapper">
        <h2 className="popup__heading-plain">{handleModalTitle()}</h2>
        <TextInput
          type="text"
          label=""
          error=""
          value={searchValue}
          onChange={(e: any) => serachNavigate(e, type!)}
          placeholder="Start typing a name..."
        />
        <div className="popup__items">{handleModalContent()}</div>
      </div>
    </Modal>
  );
};
