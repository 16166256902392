import { createSlice } from "@reduxjs/toolkit";

import { AccessControlState } from "../states/AccessControlState";
import {
  getBasicAccessControl,
  getDailyAccessControl,
  getCurrentUser,
  getWeekAccessControl,
  getStillInsideEmployees,
  getTokenInfo,
} from "../actions/accessControlActions";

const initialState: AccessControlState = {
  basicReports: [],
  dailyReports: [],
  stillInside: [],
  weekReports: null,
  user: null,
  isLoading: false,
  success: false,
};

const AccessControlSlice = createSlice({
  name: "AccessControl",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBasicAccessControl.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getBasicAccessControl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.basicReports = action.payload;
      })
      .addCase(getBasicAccessControl.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getDailyAccessControl.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getDailyAccessControl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.dailyReports = action.payload;
      })
      .addCase(getDailyAccessControl.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getWeekAccessControl.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getWeekAccessControl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.weekReports = action.payload;
      })
      .addCase(getWeekAccessControl.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.user = state.basicReports.find((item) => item.employeeId === action.payload)!;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getStillInsideEmployees.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getStillInsideEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.stillInside = action.payload;
      })
      .addCase(getStillInsideEmployees.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getTokenInfo.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getTokenInfo.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getTokenInfo.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default AccessControlSlice.reducer;
