import * as React from "react";

import TokenInfo from "components/Token-info/TokenInfo";
import { WorkspaceNav } from "components/nav";
import { Accordion } from "components/Accordion/Accordion";
import { Card } from "components/Card/Card";
import { useAppSelector } from "redux/hooks";
import { BuildingWithFloorInfoResponse } from "api/generated";
import { AppRoutes } from "config";

type BuildingsByCityType = {
  [key: number]: {
    cityName: string;
    buildings: BuildingWithFloorInfoResponse[] | undefined;
  };
};

export const Home: React.FC = () => {
  const { buildings } = useAppSelector((state) => state.building);

  const buildingsByCity = React.useMemo(
    () =>
      buildings.reduce((acc, curr) => {
        const cityId = curr.city?.id;
        if (!cityId) return acc;

        const buildingsInCity = acc[cityId]?.buildings ?? [];

        return {
          ...acc,
          [cityId]: {
            cityName: curr.city!.name!,
            buildings: [...buildingsInCity, curr],
          },
        };
      }, {} as BuildingsByCityType),
    [buildings]
  );

  const CityInfo = React.useCallback(() => {
    return (
      <>
        {Object.keys(buildingsByCity)
          .sort()
          .map((id, index) => {
            const { cityName, buildings } = buildingsByCity[parseInt(id)];
            return (
              <Accordion key={cityName} name={cityName} isAccOpen={index===0}>
                {buildings!.map((building) => (
                  <Card
                    exploreUrl={AppRoutes.createRouteForBuilding(building.id!)}
                    key={building.id}
                    buildingName={building.name}
                    buildingAddressName={building.address}
                    buidlingAddress={`https://www.google.com/maps?q=${building.latitude},${building.longitude}`}
                  />
                ))}
              </Accordion>
            );
          })}
      </>
    );
  }, [buildingsByCity]);

  return (
    <>
      <div className="homepage">
        <div className="wrap">
          <TokenInfo />
          <WorkspaceNav title="Explore our workspace" />
          <div className="accordion__container">
            <CityInfo />
          </div>
        </div>
      </div>
    </>
  );
};
