import * as React from "react";

import { useParams } from "react-router-dom";

import { getWeekAccessControl } from "../../redux/actions/accessControlActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { TimeConverter } from "../../utils/timeConverter";

import icon from "../../images/ico-chevron-down.svg";

export const WeekAccessControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const { date, employeeId } = useParams();
  const { weekReports } = useAppSelector((state) => state.accessControl);

  const [currentDay, setCurrentDay] = React.useState<string>(
    TimeConverter.getFormatedTime(date!, "dddd")
  );

  const handdleItemClick = (day: string) => {
    if (day === currentDay) {
      setCurrentDay("");
      return;
    }
    setCurrentDay(day);
  };

  React.useEffect(() => {
    dispatch(getWeekAccessControl({ employeeId: Number(employeeId), date: date! }));
  }, [employeeId, date, dispatch]);

  return (
    <div className="gate__box gate__box--split-view">
      <div className="gate__user-info">
        <h3 className="gate__box-name">{weekReports?.employeeFullName}</h3>
        <span className="gate__box-number">
          <strong>Token number:</strong> {weekReports?.employeeCardNumber}
        </span>
        <span className="gate__box-date">
          <strong>Selected day:</strong>
          {TimeConverter.getFormatedTime(date!, "DD.MM.YYYY")}
        </span>
        <span className="gate__box-total-time">
          <strong>Total time in space:</strong>
          {weekReports?.timeInSpace}
        </span>
      </div>

      <div className="gate__week">
        {weekReports?.days?.map((day) => {
          return (
            <div
              key={day.date}
              className={`gate__week-item ${
                currentDay === TimeConverter.getFormatedTime(day.date!, "dddd")
                  ? "gate__week-item--current"
                  : ""
              }`}
            >
              <button
                className="gate__week-button"
                type="button"
                onClick={() => handdleItemClick(TimeConverter.getFormatedTime(day.date!, "dddd"))}
              >
                {TimeConverter.getFormatedTime(day.date!, "dddd")}{" "}
                <img src={icon} alt="" className="gate__week-button-icon" />
              </button>

              <div className="gate__week-content">
                <span className="gate__week-total-time">
                  <strong>Total time on selected day:</strong> {day.timeInSpace}
                </span>
                {day.events?.map((event, index) => {
                  return (
                    <div key={index} className="gate__week-row">
                      <span className="gate__week-row-item">
                        <strong>Event Type:</strong> {event.eventType}
                      </span>
                      <span className="gate__week-row-item">
                        <strong>Time:</strong>{" "}
                        {TimeConverter.getFormatedTime(event.dateTime, "DD.MM.YYYY HH:mm:ss")}
                      </span>
                      <span className="gate__week-row-item">
                        <strong>Location:</strong> {event.location ?? "/"}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
