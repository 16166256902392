export const AUTHENTIFICATION_FAILED = "Incorrect username or password";
export const REQUEST_FAILED =
  "Error occurred while sending the request. Please try again.";
export const BUILDING_WARNING = "To save changes please confirm it.";
export const SUCCESS_POST = "Successfully created";
export const SUCCESS_PUT = "Successfully updated";
export const SUCCESS_DELETE = "Successfully deleted";
export const NOT_ASSIGNED = "Employee is not assign to any table";
export const EXPIRED_TOKEN =
  "Your token is expired, you will be disconnected soon";
