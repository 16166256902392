import * as React from "react";

import { Outlet } from "react-router-dom";

import { MainNav } from "../../components/nav";

export const MainLayout: React.FC = () => {
  return (
    <>
      <MainNav />
      <Outlet />
    </>
  );
};
