import * as React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "ui-components";
import logo from "../../images/vegait-logo-black.svg";
import { AppRoutes } from "../../config";

export const MainNav: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleHome = () => {
    navigate(AppRoutes.createRouteForEmployees("home"));
    setIsOpen(!isOpen);
  };
  const handleInactive = () => {
    navigate(AppRoutes.createRouteForEmployees("inactive"));
    setIsOpen(!isOpen);
  };

  const handleStillInside = () => {
    navigate(AppRoutes.createRouteForEmployees("inside"));
    setIsOpen(!isOpen);
  };

  const hamburgerClass = isOpen
    ? "header__hamburger js-header-hamburger header__hamburger--open"
    : "header__hamburger js-header-hamburger";

  const contentClass = isOpen
    ? "header__actions js-header-actions header__actions--open"
    : "header__actions js-header-actions";

  const handleToogle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = () => {
    navigate("/");
  };

  const onLogout = () => {
    localStorage.removeItem("persist:root");
    navigate(AppRoutes.createRouteForLogin());
  };

  return (
    <header className="header">
      <button onClick={handleNavigate} className="header__logo">
        <img src={logo} alt="" className="header__logo-img" />
      </button>
      <button onClick={handleToogle} className={hamburgerClass}>
        <span className="header__hamburger__bar"></span>
        <span className="sr-only">Menu</span>
      </button>
      <div className={contentClass}>
        <div className="header__buttons">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="header__nav-link"
          >
            Workspace
          </button>
          <button
            type="button"
            onClick={handleStillInside}
            className="header__nav-link"
          >
            Still inside
          </button>
          <button
            type="button"
            onClick={handleHome}
            className="header__nav-link"
          >
            Working from home
          </button>
          <button
            type="button"
            onClick={handleInactive}
            className="header__nav-link"
          >
            Inactive colleagues
          </button>
          <div className="header__logout header__logout--mobile">
            <Button onClick={onLogout} text={"Log out"} />
          </div>
        </div>
      </div>
      <div className="header__logout header__logout--desktop">
        <Button onClick={onLogout} text={"Log out"} />
      </div>
    </header>
  );
};
