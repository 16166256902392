import { createSlice } from "@reduxjs/toolkit";

import { AuthState } from "../states/AuthState";
import { login, setPassword } from "../actions/authActions";

const initialState: AuthState = {
  username: "",
  name: "",
  success: false,
  isLoading: false,
  token: "",
  exp: null,
  isAuthenticated: false,
  authority: "",
  errorMessage: "",
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.success = false;
        state.isAuthenticated = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.token = action.payload.token;
        state.name = action.payload.name;
        state.authority = action.payload.authority;
        state.exp = action.payload.exp;
        state.isLoading = false;
        state.success = true;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.success = false;
        state.isLoading = false;
        state.isAuthenticated = false;
        state.errorMessage = action.error.message!;
      })
      .addCase(setPassword.pending, (state) => {
        state.isLoading = true;
        state.success = false;
        state.isAuthenticated = false;
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
        state.isAuthenticated = false;
      })
      .addCase(setPassword.rejected, (state) => {
        state.success = false;
        state.isLoading = false;
        state.isAuthenticated = false;
      });
  },
});

export default AuthSlice.reducer;
