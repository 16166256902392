import React from 'react';

interface Props {
  onClick: () => void;
  text: string;
}

export const Button: React.FC<Props> = ({ text, onClick }) => {
  return (
    <button type="button" onClick={onClick} className="header__btn btn btn--black">
      {text}
    </button>
  );
};
